@if (initialized) {
  <ion-tabs (ionTabsWillChange)="onTabsChange($event)" class="page-portret">
    <ion-tab-bar slot="bottom">
      <ion-tab-button tab="ad6">
        <ion-icon name="home"></ion-icon>
        <ion-label>{{ 'tab.ad6' | translate }}</ion-label>
      </ion-tab-button>

      @if (eventSettings().isEventLive) {
        <ion-tab-button tab="live">
          <ion-icon name="bicycle"></ion-icon>
          <ion-label>{{ 'tab.live' | translate }}</ion-label>
          @if (unseenPassageCount() > 0) {
            <ion-badge color="danger ad6-bold">{{ unseenPassageCount() }}</ion-badge>
          }
        </ion-tab-button>
      }

      @if (eventSettings().isParticipantDataAvailable) {
        <ion-tab-button tab="favorites">
          <ion-icon name="people"></ion-icon>
          <ion-label>{{ 'tab.participants' | translate }}</ion-label>
        </ion-tab-button>
      }
      <ion-tab-button tab="more">
        <ion-icon name="ellipsis-vertical-circle"></ion-icon>
        <ion-label>{{ 'tab.more' | translate }}</ion-label>
        @if (unreadNotifications().length > 0) {
          <ion-badge color="danger">{{ unreadNotifications().length }}</ion-badge>
        }
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
}
